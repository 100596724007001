// header
$header-background-color: black;
$header-text-color: lightGray;
$menu-hilight-background: orange;
$menu-hilight-color: white;
$menu-background-color: black;

//main
$main-text-color: black;
$main-link-color: orange;

$section-1-background-color: rgba(0, 0, 0, 0.66);
$section-1-text-color: white;

$section-2-background-color: whitesmoke;
$section-3-background-color: white;

// form
$form-background-color: whitesmoke;
$form-text-color: black;
$form-border-color: lightGray;
$form-border-error: red;
$form-border-focus: deepskyblue;

$submit-border-color: transparent;
$submit-background-color: orange;
$submit-text-color: white;

// footer

$footer-background-color: #4D6A79;
$footer-text-color: white;
$footer-border-color: lightGray;

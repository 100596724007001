@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

@keyframes displayTransition {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

.wait {
  animation: displayTransition 0.5s;
  display: flex;
  opacity: 0.5;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  background-color: black;
  border-radius: 0.25rem;
  justify-content: center;
  align-items: center;
  .icon {
    font-size: 3rem;
    animation: spin infinite 2s linear;
    color: white;
  }
}

@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');
@import url('//cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/leaflet.css');

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto Condensed', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: inherit;
  color: inherit;
  -webkit-tap-highlight-color: transparent;
}

.leaflet-container {
  height: 70vh;
}
@import "../assets/colors.scss";

.container {
  color: $footer-text-color;
  background: $footer-background-color;
  padding: 0 !important;
  .content {
    display: flex;
    flex-wrap: wrap;
    .contactsContainer {
      flex: 1 1 auto;
      display: flex;
      justify-content: flex-end;
      max-width: 640px;
      padding-left: 1rem;
      .contacts {
        padding: 1rem;
        padding-right: 2rem;
        list-style-type: square;
        li {
          padding: 0.25rem 0;
        }
      }
    }
    .mapContainer {
      background: $form-background-color;
      flex: 1 1 auto;
      min-height: 400px;
      border: none;
    }
  }
}
@import "../assets/colors.scss";

.container {
  position: relative;
  padding: 0;
  font-size: 1.2rem;
  font-family: "Roboto";
  margin: 0.5rem 0;
  border-radius: 0.25rem;
  border: 2px solid;
  outline: none;
  width: 100%;
  border-color: $form-border-color;

  &.error {
    border-color: $form-border-error;
    .hint {
      color: $form-border-error;
    }
  }

  &:focus-within {
    border-color: $form-border-focus;
  }

  .input {
    color: $form-text-color;
    background-color: $form-background-color;
    // background: transparent;
    padding: 1.5rem 1rem 0.5rem 1rem;
    font-size: 1.1rem;
    border: none;
    outline: none;
    width: 100%;
    transition: all 0.5s;
    &.empty {
      padding: 1rem;  
    }
  }

  .hint {
    color: $form-text-color;
    opacity: 0.75;
    font-family: "Roboto";
    font-size: 0.8rem;
    position: absolute;
    top: 0.2rem;
    left: 1rem;
    text-transform: lowercase;
  }
    
  .hint2 {
    color: $form-text-color;
    opacity: 0.75;
    font-family: "Roboto Condensed";
    font-size: 0.8rem;
    position: absolute;
    top: 0.2rem;
    right: 0.3rem;
    // bottom: 0.3rem;
    text-transform: lowercase;
  }
}

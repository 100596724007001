@import "../assets/colors.scss";

.container {
  background: $header-background-color;
  color: $header-text-color;
  padding: 0 1rem;
  .content .logo {
    padding: 0 1rem;
    font-size: 2rem;
    color: orangered;
    // transform: rotateY(180deg);
  }
  @media only screen and (min-width: 960px) {
    .content {
      max-width: 1280px;
      height: 5rem;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .contacts {
        white-space: nowrap;
      }
      .menu {
        padding: 0 1rem;
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        font-size: 1.5rem;
        .menuItem {
          height: 5rem;
          display: flex;
          align-items: center;
          transition: all 0.25s;
          &:hover {
            background: $menu-hilight-background;
            color: $menu-hilight-color;
          }
          white-space: nowrap;
          padding: 0 1rem;
        }
      }
      .bars {
        display: none;
      }
    }
  }
  @media only screen and (max-width: 960px) {
    .content {
      max-width: 1280px;
      height: 5rem;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .contacts {
        display: none;
      }
      .menu {
        position: absolute;
        z-index: 3;
        background: $menu-background-color;
        display: flex;
        flex-direction: column;
        top: 0;
        padding: 1rem;
        height: 100%;
        left: -100%;
        box-shadow: 0 0 1rem black;
        transition: all 0.5s;
        &.open {
          left: 0;
        }
        .menuItem {
          font-size: 1.3rem;
          padding: 0.75rem 0.25rem;
        }
      }
      .bars {
        font-size: 2rem;
        padding: 0.5rem;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
      }
    }
  }
}
